import React, { useContext } from "react";
import { GeneralContext } from "../../context/StateContext";
import { useRouter } from "../../hooks/useRouter";

const ProductCard = ({ data }) => {
  const { setModalOpen, setData } = useContext(GeneralContext);

  const handleClick = (e) => {
    setData(data);
    setModalOpen(true);
  };

  return (
    <div className="Nevitta__ProductCard">
      <div className="productImg">
        <img src={data.image} alt="demo" id={data.id} onClick={handleClick} />
      </div>
      <div className="productTitle" id={data.id} onClick={handleClick}>
        {data.title}
      </div>
    </div>
  );
};

export default ProductCard;
