import React, { useContext } from "react";
import styled from "styled-components";
import { GeneralContext } from "../../context/StateContext";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

const SideBarContainer = styled.div`
  transition: all 0.5s ease-in-out;
  transform: translateY(${(props) => (props.open ? "0%" : "-200%")});
  width: 100%;
  height: 150%;
  padding: 0 0 4em 0;
  position: fixed;
  z-index: 99999;
  top: 5em;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  display: flex;
  justify-content: center;
`;

const SideBar = () => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const { isOpen, setOpen } = useContext(GeneralContext);

  const handleOnClick = () => setOpen(!isOpen);

  return (
    <SideBarContainer open={isOpen}>
      <ul className="Nevitta__SideBar">
        <Link
          to="/"
          className={splitLocation[1] === "" ? "active" : ""}
          onClick={handleOnClick}
        >
          <li>Home</li>
        </Link>
        <Link
          to="/about"
          className={splitLocation[1] === "about" ? "active" : ""}
          onClick={handleOnClick}
        >
          <li>About Us</li>
        </Link>
        <Link
          to="/products"
          className={splitLocation[1] === "products" ? "active" : ""}
          onClick={handleOnClick}
        >
          <li>Products</li>
        </Link>

        <Link
          to="/inquiry"
          className={splitLocation[1] === "inquiry" ? "active" : ""}
          onClick={handleOnClick}
        >
          <li>Inquiry</li>
        </Link>
        <Link
          to="/contact"
          className={splitLocation[1] === "contactus" ? "active" : ""}
          onClick={handleOnClick}
        >
          <li>Contact Us</li>
        </Link>
        <Link to="/contact" className="sidebarPhone" onClick={handleOnClick}>
          <span>
            <FiPhoneCall color="white" size="1em" />
          </span>
          <li>+91 63568 38664</li>
        </Link>
        <Link to="/" className="sidebarEmail" onClick={handleOnClick}>
          <span>
            <FiMail />
          </span>
          <li>nevittaproducts@gmail.com</li>
        </Link>
      </ul>
    </SideBarContainer>
  );
};

const PhoneIcon = (props) => (
  <svg
    stroke="white"
    fill="white"
    strokeWidth={2}
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
  </svg>
);

export default SideBar;
