import mainCategoryImages from "./images";

const mainCategory = [
  {
    title: "Cabinet Handles",
    image: mainCategoryImages.CabinetHandles,
    path: "cabinethandles?categoryId=1",
  },
  {
    title: "Pull Handles",
    image: mainCategoryImages.PullHandles,
    path: "pullhandles?categoryId=2",
  },
  {
    title: "Mortise Handles",
    image: mainCategoryImages.MortiseHandles,
    path: "mortisehandles?categoryId=3",
  },
  {
    title: "Profile Handles",
    image: mainCategoryImages.ProfileHandles,
    path: "profilehandles?categoryId=4",
  },
  {
    title: "Drawer Knobs",
    image: mainCategoryImages.DrawerKnobs,
    path: "drawerknobs?categoryId=5",
  },
  {
    title: "SS Handles",
    image: mainCategoryImages.SSHandles,
    path: "sshandles?categoryId=6",
  },
  {
    title: "Drawer Slides",
    image: mainCategoryImages.DrawerSlides,
    path: "drawerslides?categoryId=7",
  },
  {
    title: "LockBody & Cylinder",
    image: mainCategoryImages.LockBodyandCylinder,
    path: "lockbody&cylinder?categoryId=8",
  },
  {
    title: "Hinges",
    image: mainCategoryImages.Hinges,
    path: "hinges?categoryId=9",
  },
  {
    title: "Tower Bolt",
    image: mainCategoryImages.TowerBolt,
    path: "towerbolt?categoryId=10",
  },
  {
    title: "Door Closer & Viewer",
    image: mainCategoryImages.DoorCloserandViewer,
    path: "doorcloser&viewer?categoryId=11",
  },
  {
    title: "Wall Hooks",
    image: mainCategoryImages.WallHooks,
    path: "wallhooks?categoryId=12",
  },
];

export default mainCategory;
