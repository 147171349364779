import React from "react";
import {
  RiFacebookFill,
  RiInstagramFill,
  RiLinkedinFill,
  RiTwitterFill,
  RiPinterestFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

const Footer = () => {
  return (
    <div
      className="Nevitta__FooterContainer"
      data-scroll
      data-scroll-speed={-7}
    >
      <nav className="footerLinks">
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/about">
          <li>About Us</li>
        </Link>
        <Link to="/products">
          <li>Products</li>
        </Link>
        <Link to="/inquiry">
          <li>Inquiry</li>
        </Link>
        <Link to="/contact">
          <li>Contact Us</li>
        </Link>
      </nav>

      <img src={Logo} alt="Nevitta Logo" />
      <div className="footerSocialMediaLinks">
        <a
          href="https://www.facebook.com/Nevitta-Architectural-Products-102570738632289/"
          target="_blank"
          rel="noreferrer"
        >
          <RiFacebookFill />
        </a>
        <a href="https://twitter.com/NevittaP" target="_blank" rel="noreferrer">
          <RiTwitterFill />
        </a>
        <a
          href="https://www.linkedin.com/company/nevitta-architectural-products"
          target="_blank"
          rel="noreferrer"
        >
          <RiLinkedinFill />
        </a>
        <a
          href="https://www.instagram.com/nevitta_architectural_products/"
          target="_blank"
          rel="noreferrer"
        >
          <RiInstagramFill />
        </a>
        <a
          href="https://in.pinterest.com/nevittamanufacturer"
          target="_blank"
          rel="noreferrer"
        >
          <RiPinterestFill />
        </a>
      </div>

      <div className="footerCopyright">
        <h5>&copy; NEVITTA 2021. All Rights Reserved.</h5>
        <p>HANDCRAFTED WITH ❤️ BY MEET MANDAVIYA.</p>
      </div>
    </div>
  );
};

export default Footer;
