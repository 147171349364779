import React, { useRef } from "react";
import Header from "../../components/header/index";
import Footer from "../footer/Footer";

const Layout = (props) => {
  return (
    <>
      <section className="Nevitta__Layout">
        <Header />
        {props.children}
        <Footer />
      </section>
    </>
  );
};

export default Layout;
