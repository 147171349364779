import React from "react";
import LoaderImg from "../../assets/img/loader.svg";
import "./Loader.css";
import styled from "styled-components";

const LoadingContainer = styled.div`
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: visible;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: ${(props) => (props.load ? "99999" : "-1")};
  width: 100%;
  opacity: ${(props) => (props.load ? "1" : "0")};
  transition: all ease-in-out 1s;
  background-color: black;
`;

const Loader = ({ loading }) => {
  return (
    <LoadingContainer load={loading}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="-138 0 720 110"
      >
        <path
          className="nevittagold"
          d="M1494.65,1507.89c0,4,0,7.63,0,11.29,0,2,.09,4,.1,6,0,6.42,0,12.84.09,19.26,0,1-.25,1.28-1.25,1.26-3.48-.06-7,0-10.43,0-.81,0-1.1-.18-1.1-1.05q0-32.23,0-64.48c0-.32-.21-.79.24-1s.63.27.88.51l22.65,20.83,20.72,19c.14.13.3.23.47.37a1.73,1.73,0,0,0,.16-1.2q0-15.13-.1-30.26c0-1.88,0-3.75-.15-5.62,0-.89.23-1.16,1.14-1.15,3.48,0,7,.06,10.43,0,1,0,1.16.38,1.16,1.25q0,16.69,0,33.38v32.23Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1414.27,1525c0-3.21-.11-6.32,0-9.41.15-3.54.08-7.09.18-10.64,0-1.43.09-2.86.17-4.3a1.36,1.36,0,0,0-.7-1.37q-11.15-7.37-22.23-14.78a1.25,1.25,0,0,0-1.52-.06q-12,6.69-24,13.34a1.67,1.67,0,0,0-1,1.66c-.13,9.08-.31,18.17-.49,27.25-.12,5.9-.29,11.8-.39,17.69a1.54,1.54,0,0,1-.94,1.47c-2.9,1.61-5.78,3.24-8.67,4.87-1.12.63-1.14.63-1.15-.66,0-9,0-18-.14-27-.17-9.93-.09-19.86-.23-29.78a2.05,2.05,0,0,1,1.14-2.09q17.45-10,34.85-20.09a1.37,1.37,0,0,1,1.63,0q17.23,10.73,34.49,21.4a1.59,1.59,0,0,1,.87,1.63c-.26,5.49-.47,11-.69,16.47a1.89,1.89,0,0,1-.32,1,129.42,129.42,0,0,1-8.92,11.7A7,7,0,0,1,1414.27,1525Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1344.71,1532.31c-.68,2.61-1.65,5.15-2.11,7.83a24.17,24.17,0,0,0,.15,10.77c1.6,5.77,5.5,9.09,11.28,10.29s11.51.15,17.09-1.57c10.05-3.11,19-8.37,27.27-14.69a116.56,116.56,0,0,0,28.67-30.9c4-6.39,7.21-13.14,8.62-20.61a24.57,24.57,0,0,0,0-9.85,14.11,14.11,0,0,0-12.13-11.18,31.28,31.28,0,0,0-8.59,0,6.27,6.27,0,0,1-.76,0c6.18-2.06,14.88-2.76,20.73-.46s9.22,6.78,10.17,13c1,6.62-.52,12.91-2.84,19-3.76,9.91-9.49,18.67-16.19,26.81a135.66,135.66,0,0,1-36.77,31.42c-7.77,4.51-15.93,8.11-24.86,9.68-5.49,1-11,1.14-16.25-1.07-5.64-2.36-8.62-6.81-9.54-12.67-1-6.58.51-12.83,2.79-18.94a40.55,40.55,0,0,1,3.14-7Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1687.6,1531.38c0,4.39,0,8.77,0,13.16,0,.91-.26,1.14-1.15,1.13-3.28-.05-6.55-.06-9.83,0-1,0-1.27-.24-1.26-1.25,0-8.69,0-17.38,0-26.08,0-1.19-.33-1.51-1.47-1.42s-2.39,0-3.59,0c-.64,0-.94-.14-.93-.86,0-2.55,0-5.11,0-7.66,0-.78.33-.93,1-.91,1.28,0,2.56,0,3.84,0,.9,0,1.15-.25,1.14-1.14,0-4.67,0-9.34,0-14,0-1,.18-1.46,1.35-1.42q4.79.12,9.59,0c1.11,0,1.31.35,1.3,1.36,0,4.67,0,9.33,0,14,0,1,.28,1.22,1.21,1.2,2.32-.06,4.64,0,7,0,.78,0,1,.22,1,1,0,2.47,0,5,0,7.42,0,.78-.23,1-1,1-2.23,0-4.48,0-6.71,0-1.13,0-1.5.22-1.48,1.42C1687.65,1522.69,1687.6,1527,1687.6,1531.38Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1716.64,1531.33c0,4.39,0,8.77,0,13.16,0,1-.29,1.19-1.21,1.18-3.28-.05-6.55-.06-9.83,0-.94,0-1.21-.24-1.2-1.19,0-8.66,0-17.31,0-26,0-1.78.18-1.56-1.63-1.57-1.08,0-2.16,0-3.23,0-.7,0-1-.17-1-.93,0-2.51,0-5,0-7.54,0-.72.22-1,1-1,1.28,0,2.56,0,3.84,0,.86,0,1.07-.27,1.07-1.09,0-4.7,0-9.41,0-14.11,0-1,.23-1.38,1.31-1.35,3.24.07,6.48.06,9.71,0,.93,0,1.23.22,1.22,1.19-.05,4.7,0,9.41,0,14.11,0,1,.3,1.28,1.28,1.25,2.32-.07,4.64,0,6.95,0,.73,0,1,.23,1,1q0,3.78,0,7.54c0,.75-.26,1-1,.94-2.36,0-4.72,0-7.08,0-.88,0-1.16.2-1.15,1.12C1716.67,1522.48,1716.64,1526.9,1716.64,1531.33Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1618.59,1525.67c2.37-5.48,4.65-10.74,6.92-16a.56.56,0,0,0,0-.12c.8-1.92.8-1.92,2.93-1.92H1640c-1,1.77-1.85,3.4-2.75,5q-9,16.14-18,32.28c-.29.51-.43,1.34-1,1.36s-.76-.83-1-1.32q-10.65-18.08-21.26-36.18c-.65-1.1-.62-1.16.65-1.16,4,0,8,0,12,0a1.52,1.52,0,0,1,1.63,1c2.63,5.46,5.31,10.89,8,16.33C1618.26,1525.13,1618.37,1525.29,1618.59,1525.67Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1369.75,1542.75a2.22,2.22,0,0,1-.18-1.36q0-16.8.1-33.61c0-1.91.1-3.82.11-5.74a1.25,1.25,0,0,1,.71-1.23q9.06-5,18.07-10.12a1.41,1.41,0,0,1,1.64,0q9.29,5.57,18.62,11.07a1.6,1.6,0,0,1,.88,1.64q-.2,13-.34,26a2,2,0,0,1-.46,1.43c-1.44,1.55-2.86,3.11-4.35,4.73a1.88,1.88,0,0,1-.21-1.3c0-9.17,0-18.34,0-27.51a2.23,2.23,0,0,0-1.22-2.19q-6.15-3.72-12.22-7.56a1.65,1.65,0,0,0-2-.08q-6.4,3.63-12.87,7.18a1.3,1.3,0,0,0-.72,1.34c0,2.43,0,4.87,0,7.3-.09,8.61-.2,17.22-.27,25.83a1.76,1.76,0,0,1-1.06,1.78C1372.57,1541.1,1371.18,1541.94,1369.75,1542.75Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1647.11,1526.62c0-5.94,0-11.88,0-17.82,0-1,.26-1.21,1.2-1.2,3.32.06,6.63.05,10,0,.81,0,1.1.2,1.09,1.06q0,18,0,36c0,.79-.24,1-1,1-3.39,0-6.79,0-10.18,0-.9,0-1-.33-1-1.11C1647.12,1538.58,1647.11,1532.6,1647.11,1526.62Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1653.45,1500.22c-3.73,0-6.61-2.71-6.6-6.12s3.11-6.12,6.7-6.11a6.41,6.41,0,0,1,6.5,6.26A6.28,6.28,0,0,1,1653.45,1500.22Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1765.59,1541c-2.66,2.6-5.4,4.49-8.79,5.2-7.4,1.55-15.57-.82-19.74-7.14a20.72,20.72,0,0,1-3.06-10.31c-.38-6,.77-11.54,4.67-16.25a17.75,17.75,0,0,1,16-6.69,15.28,15.28,0,0,1,11,5.7c.24-1.12.48-2,.62-3,.09-.62.31-.91,1-.9,3.24,0,6.48,0,9.71,0,.89,0,.83.5.83,1.08q0,6.4,0,12.8c0,7.66,0,15.32,0,23,0,.94-.24,1.22-1.19,1.2-3.28-.06-6.56-.05-9.84,0-.9,0-1.3-.18-1.21-1.17S1765.59,1542.37,1765.59,1541Zm0-17.88a5,5,0,0,0-1-3.34,10.37,10.37,0,0,0-12.65-3.2c-4.06,2.08-5.68,5.58-5.56,10.05a10.3,10.3,0,0,0,14.26,9.36,7.6,7.6,0,0,0,5-7.36v-2.28C1765.59,1525.29,1765.52,1524.21,1765.6,1523.13Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1424.61,1553.19l-7.85-5.41c-.68-.47-.11-.69.2-.94a44.61,44.61,0,0,0,6.68-6.64c.41-.51.79-1,1.41-1.87Z"
          transform="translate(-1338.36 -1470.48)"
        />
        <path
          className="nevittagold"
          d="M1573.9,1500.75c10.23-.2,17.45,6,19.9,16.49.07.31.13.62.22.93.19.71-.07,1.07-.79,1.29-4.72,1.48-9.42,3-14.13,4.51-3.95,1.26-7.89,2.56-11.86,3.76-1.08.33-.6.65-.15,1,2.88,2.49,6.19,2.92,9.77,2a11.45,11.45,0,0,0,3.83-1.65c.63-.42.92-.29,1.29.34,1.47,2.55,3,5.08,4.51,7.61.22.36.44.62-.12.93-8.42,4.72-19.19,4.84-26.72-.46a17.79,17.79,0,0,1-7.27-12.07,20.24,20.24,0,0,1,3.26-15.71A22.25,22.25,0,0,1,1573.9,1500.75ZM1564,1520c-.12.9.13,1.26,1.08.84.62-.27,1.28-.45,1.92-.67,4.23-1.43,8.46-2.88,12.7-4.27.84-.28,1-.6.58-1.35a7.52,7.52,0,0,0-3-3.34,9,9,0,0,0-11.6,2.66,10.39,10.39,0,0,0-1.7,5.52A3.77,3.77,0,0,1,1564,1520Z"
          transform="translate(-1338.36 -1470.48)"
        />
      </svg>
    </LoadingContainer>
  );
};

export default Loader;
