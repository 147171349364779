import React from "react";
import Carousel from "../components/carousel/Carousel";
import Certificate from "../assets/img/certificateOfRegistration.jpeg";
import logo from "../assets/img/logo.png";
import SliderProductCard from "../components/sliderProductCard/SliderProductCard";
import "react-alice-carousel/lib/alice-carousel.css";
import ProductCarousel from "../components/carousel/ProductCarousel/ProductCarousel";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import mainCategory from "../data/mainCategory";
import { useRouter } from "../hooks/useRouter";

const Home = () => {
  const router = useRouter();

  const items = mainCategory.map((e, index) => (
    <SliderProductCard
      title={e.title}
      img={e.image}
      path={e.path}
      key={index}
    />
  ));

  return (
    <div className="Nevitta__Home">
      {/* Carousel */}
      <Carousel />
      {/* Carousel End */}

      {/* Welcome */}
      <div className="welcomeContainer">
        <div className="welcome">
          <img
            className="welcomeBg"
            src="/assets/images/backgrounds/CabinetHandle.jpeg"
            alt="Nevitta Welcome Banner"
          />
          <Zoom>
            <div className="Nevitta__heading">
              <h1>Welcome To</h1>
              <img src={logo} alt="Nevitta Logo" />
            </div>
          </Zoom>
          <Fade left duration={1500}>
            <h2>Indian manufactures of the finest architectural hardware</h2>
          </Fade>
          <p>We want to feel part of that</p>
        </div>
      </div>
      {/* Welcome End */}

      {/* Certification */}
      <div className="certificationContainer">
        <div className="Nevitta__heading">
          <h1>Certification Of</h1>
          <img src={logo} alt="Nevitta Logo" />
        </div>
        <div className="certification">
          {/* <Bounce left>
            <img
              data-scroll
              data-scroll-speed={1}
              className="certificate"
              src={Certificate}
              alt="Nevitta Registration Certificate"
            />
          </Bounce> */}
          <div className="certificationContentContainer">
            <Fade right when={true} appear>
              <p className="certificationContent">
                We started manufacturing in 2006 as a Name with Duster Metal
                Products Pvt. Ltd. Now we are going globally with Nevitta. We
                understood the importance of innovative design and an
                uncompromising commitment to craft and quality.
              </p>
              <p className="certificationContent">
                Our hardware fittings accessories are highly innovative, highly
                designed product of expression that open up infinite
                possibilities for defining the modern living space.
              </p>
              <p className="certificationContent">
                We prides on quality and performance.
              </p>
            </Fade>
          </div>
        </div>
      </div>
      {/* Certification End */}

      {/* Products nevitta */}
      <div className="productsContainer">
        <div className="Nevitta__heading">
          <h1>Products Of</h1>
          <img src={logo} alt="Nevitta Logo" />
        </div>
        <ProductCarousel items={items} />
        <div className="products"></div>
      </div>
      {/* End Products Nevitta */}

      {/* CTA */}
      <div className="ctaContainer">
        <div className="ctaLogo">
          <img src={logo} alt="Logo Container" />
        </div>
        <div className="ctaContent">
          <Bounce top>
            <p className="ctaHeading">LOOKING FORWARD TO WORK WITH US...!</p>
          </Bounce>
          <Fade left duration={1200}>
            <p className="ctaSubtitle">LET'S TURN BUSINESS INTO FAMILY</p>
          </Fade>
          <div className="ctaButtonContainer">
            <Bounce duration={500}>
              <button
                className="ctaButton"
                onClick={() => {
                  router.push("/contact");
                }}
              >
                Get Free Quote & Sample Now!
              </button>
            </Bounce>
            <button className="ctaButton ">Download Broucher</button>
          </div>
        </div>
      </div>
      {/* CTA END */}
    </div>
  );
};

export default Home;
