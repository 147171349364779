import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useRouter } from "../../hooks/useRouter";

const SliderProductCard = ({ title, path, img }) => {
  const router = useRouter();

  const handleClick = (e) => {
    let path = e.target.id;
    router.push(`/products/${path}`);
  };

  return (
    <div className="Nevitta__ProductCardContainer">
      <div className="productImage">
        <img src={img} alt={img} id={path} onClick={handleClick} />
      </div>
      <div className="productContent">
        <div className="productTitle" id={path}>
          {title}
        </div>
        {/* <div className="productDesc">{desc}</div> */}
        <div className="productDetails">
          <a id={path} onClick={handleClick}>
            More Details
            <HiArrowNarrowRight className="rightIcon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SliderProductCard;
