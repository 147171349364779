export const mainCategoryImages = {
  CabinetHandles: "/assets/images/products/CabinetHandles/CabinetHandle.jpeg",
  PullHandles: "/assets/images/products/PullHandles/PullHandle.jpeg",
  MortiseHandles: "/assets/images/products/MortiseHandles/MortiseHandle.jpeg",
  ProfileHandles: "/assets/images/products/ProfileHandles/ProfileHandle.jpeg",
  DrawerKnobs: "/assets/images/products/DrawerKnobs/DrawerKnob.jpeg",
  SSHandles: "/assets/images/products/SSHandles/SSHandle.jpeg",
  DrawerSlides: "/assets/images/products/DrawerSlides/DrawerSlide.jpeg",
  FittingAccessories:
    "/assets/images/products/FittingAccessories/FittingAccesory.jpeg",
  TowerBolt: "/assets/images/products/TowerBolt/NPTB_703.jpg",
  Hinges: "/assets/images/products/Hinges/NPH_03.jpg",
  LockBodyandCylinder: "/assets/images/products/LockBody&Cylinder/NPLB_02.jpeg",
  DoorCloserandViewer: "/assets/images/products/DoorCloser&Viewer/NPDC_01.jpg",
  WallHooks: "/assets/images/products/WallHooks/NPWH_02.jpg",
};

export const mortiseHandlesImages = {
  NPMH_01: `/assets/images/products/MortiseHandles/NPMH_01.jpg`,
  NPMH_02: `/assets/images/products/MortiseHandles/NPMH_02.jpeg`,
  NPMH_03: `/assets/images/products/MortiseHandles/NPMH_03.jpg`,
  NPMH_04: `/assets/images/products/MortiseHandles/NPMH_04.jpg`,
  NPMH_05: `/assets/images/products/MortiseHandles/NPMH_05.jpg`,
  NPMH_06: `/assets/images/products/MortiseHandles/NPMH_06.jpeg`,
  NPMH_07: `/assets/images/products/MortiseHandles/NPMH_07.jpeg`,
  NPMH_08: `/assets/images/products/MortiseHandles/NPMH_08.jpeg`,
  NPMH_09: `/assets/images/products/MortiseHandles/NPMH_09.jpeg`,
  NPMH_10: `/assets/images/products/MortiseHandles/NPMH_10.jpeg`,
  NPMH_11: `/assets/images/products/MortiseHandles/NPMH_11.jpg`,
  NPMH_12: `/assets/images/products/MortiseHandles/NPMH_12.jpg`,
  NPMH_13: `/assets/images/products/MortiseHandles/NPMH_13.jpg`,
  NPMH_14: `/assets/images/products/MortiseHandles/NPMH_14.jpeg`,
  NPMH_15: `/assets/images/products/MortiseHandles/NPMH_15.jpeg`,
  NPMH_16: `/assets/images/products/MortiseHandles/NPMH_16.jpeg`,
  NPMH_17: `/assets/images/products/MortiseHandles/NPMH_17.jpeg`,
  NPMH_18: `/assets/images/products/MortiseHandles/NPMH_18.jpeg`,
  NPMH_19: `/assets/images/products/MortiseHandles/NPMH_19.jpeg`,
  NPMH_20: `/assets/images/products/MortiseHandles/NPMH_20.jpeg`,
  NPMH_21: `/assets/images/products/MortiseHandles/NPMH_21.jpeg`,
  NPMH_22: `/assets/images/products/MortiseHandles/NPMH_22.jpeg`,
  NPMH_23: `/assets/images/products/MortiseHandles/NPMH_23.jpeg`,
  NPMH_24: `/assets/images/products/MortiseHandles/NPMH_24.jpeg`,
  NPMH_25: `/assets/images/products/MortiseHandles/NPMH_25.jpeg`,
  NPMH_26: `/assets/images/products/MortiseHandles/NPMH_26.jpeg`,
  NPMH_27: `/assets/images/products/MortiseHandles/NPMH_27.jpg`,
  NPMH_28: `/assets/images/products/MortiseHandles/NPMH_28.jpeg`,
  NPMH_29: `/assets/images/products/MortiseHandles/NPMH_29.jpeg`,
  NPMH_30: `/assets/images/products/MortiseHandles/NPMH_30.jpeg`,
  NPMH_31: `/assets/images/products/MortiseHandles/NPMH_31.jpeg`,
  NPMH_32: `/assets/images/products/MortiseHandles/NPMH_32.jpeg`,
  NPMH_33: `/assets/images/products/MortiseHandles/NPMH_33.jpeg`,
  NPMH_34: `/assets/images/products/MortiseHandles/NPMH_34.jpeg`,
  NPMH_35: `/assets/images/products/MortiseHandles/NPMH_35.jpeg`,
  NPMH_36: `/assets/images/products/MortiseHandles/NPMH_36.jpeg`,
  NPMH_37: `/assets/images/products/MortiseHandles/NPMH_37.jpeg`,
  NPMH_38: `/assets/images/products/MortiseHandles/NPMH_38.jpeg`,
};

export const drawerKnobsImages = {
  NPDN_01: `/assets/images/products/DrawerKnobs/NPDN_01.jpg`,
  NPDN_02: `/assets/images/products/DrawerKnobs/NPDN_02.jpg`,
  NPDN_03: `/assets/images/products/DrawerKnobs/NPDN_03.jpg`,
  NPDN_04: `/assets/images/products/DrawerKnobs/NPDN_04.jpg`,
  NPDN_05: `/assets/images/products/DrawerKnobs/NPDN_05.jpg`,
  NPDN_06: `/assets/images/products/DrawerKnobs/NPDN_06.jpg`,
  NPDN_07: `/assets/images/products/DrawerKnobs/NPDN_07.jpg`,
  NPDN_08: `/assets/images/products/DrawerKnobs/NPDN_08.jpg`,
  NPDN_09: `/assets/images/products/DrawerKnobs/NPDN_09.jpg`,
  NPDN_10: `/assets/images/products/DrawerKnobs/NPDN_010.jpg`,
  NPDN_11: `/assets/images/products/DrawerKnobs/NPDN_11.jpg`,
  NPDN_12: `/assets/images/products/DrawerKnobs/NPDN_12.jpg`,
};

export const ssHandlesImages = {
  NPSSDH_01: `/assets/images/products/SSHandles/NPSSDH_01.jpeg`,
  NPSSDH_02: `/assets/images/products/SSHandles/NPSSDH_02.jpeg`,
  NPSSDH_03: `/assets/images/products/SSHandles/NPSSDH_03.jpeg`,
  NPSSDH_04: `/assets/images/products/SSHandles/NPSSDH_04.jpeg`,
  NPSSDH_05: `/assets/images/products/SSHandles/NPSSDH_05.jpeg`,
  NPSSDH_06: `/assets/images/products/SSHandles/NPSSDH_06.jpeg`,
  NPSSDH_07: `/assets/images/products/SSHandles/NPSSDH_07.jpeg`,
  NPSSDH_08: `/assets/images/products/SSHandles/NPSSDH_08.jpeg`,
  NPSSDH_09: `/assets/images/products/SSHandles/NPSSDH_09.jpeg`,
  NPSSDH_10: `/assets/images/products/SSHandles/NPSSDH_10.jpeg`,
  NPSSDH_11: `/assets/images/products/SSHandles/NPSSDH_11.jpeg`,
  NPSSDH_12: `/assets/images/products/SSHandles/NPSSDH_12.jpeg`,
  NPSSDH_13: `/assets/images/products/SSHandles/NPSSDH_13.jpeg`,
  NPSSDH_14: `/assets/images/products/SSHandles/NPSSDH_14.jpeg`,
  NPSSDH_15: `/assets/images/products/SSHandles/NPSSDH_15.jpeg`,
  //
  NPSSMH_01: `/assets/images/products/SSHandles/NPSSMH_01.jpeg`,
  NPSSMH_02: `/assets/images/products/SSHandles/NPSSMH_02.jpeg`,
  NPSSMH_03: `/assets/images/products/SSHandles/NPSSMH_03.jpeg`,
  NPSSMH_04: `/assets/images/products/SSHandles/NPSSMH_04.jpeg`,
  //
  NPSSNR_01: `/assets/images/products/SSHandles/NPSSNR_01.jpeg`,
  NPSSNR_02: `/assets/images/products/SSHandles/NPSSNR_02.jpeg`,
  NPSSNR_03: `/assets/images/products/SSHandles/NPSSNR_03.jpeg`,
  NPSSNR_04: `/assets/images/products/SSHandles/NPSSNR_04.jpeg`,
  NPSSNR_05: `/assets/images/products/SSHandles/NPSSNR_05.jpeg`,
  //
  NPSSTH_01: `/assets/images/products/SSHandles/NPSSTH_01.jpeg`,
  NPSSTH_02: `/assets/images/products/SSHandles/NPSSTH_02.jpeg`,
  NPSSTH_03: `/assets/images/products/SSHandles/NPSSTH_03.jpeg`,
  NPSSTH_04: `/assets/images/products/SSHandles/NPSSTH_04.jpeg`,
};

export const towerBoltImages = {
  NPTB_701: `/assets/images/products/TowerBolt/NPTB_701.jpg`,
  NPTB_702: `/assets/images/products/TowerBolt/NPTB_702.jpg`,
  NPTB_703: `/assets/images/products/TowerBolt/NPTB_703.jpg`,
  NPTB_704: `/assets/images/products/TowerBolt/NPTB_704.jpg`,
  NPTB_705: `/assets/images/products/TowerBolt/NPTB_705.jpg`,
  NPTB_706: `/assets/images/products/TowerBolt/NPTB_706.jpg`,
  NPTB_707: `/assets/images/products/TowerBolt/NPTB_707.jpg`,
};

export const DoorCloserandViewerImages = {
  NPDC_01: `/assets/images/products/DoorCloser&Viewer/NPDC_01.jpg`,
  NPDC_02: `/assets/images/products/DoorCloser&Viewer/NPDC_02.jpg`,
  NPDV_03: `/assets/images/products/DoorCloser&Viewer/NPDV_03.jpg`,
};

export const wallHooksImages = {
  NPWH_01: `/assets/images/products/WallHooks/NPWH_01.jpg`,
  NPWH_02: `/assets/images/products/WallHooks/NPWH_02.jpg`,
};

export const HingesImages = {
  NPH_01: `/assets/images/products/Hinges/NPH_01.jpg`,
  NPH_02: `/assets/images/products/Hinges/NPH_02.jpg`,
  NPH_03: `/assets/images/products/Hinges/NPH_03.jpg`,
  NPH_04: `/assets/images/products/Hinges/NPH_04.jpg`,
  NPH_05: `/assets/images/products/Hinges/NPH_05.jpg`,
  NPH_06: `/assets/images/products/Hinges/NPH_06.jpg`,
};

export const LockBodyandCylinder = {};

export const pullHandles = {
  NDH_01: `/assets/images/products/PullHandles/NDH_01.jpg`,
  NDH_02: `/assets/images/products/PullHandles/NDH_02.jpg`,
  NDH_03: `/assets/images/products/PullHandles/NDH_03.jpg`,
  NDH_04: `/assets/images/products/PullHandles/NDH_04.jpg`,
  NDH_05: `/assets/images/products/PullHandles/NDH_05.jpg`,
  NDH_06: `/assets/images/products/PullHandles/NDH_06.jpg`,
  NDH_07: `/assets/images/products/PullHandles/NDH_07.jpeg`,
  NDH_08: `/assets/images/products/PullHandles/NDH_08.jpeg`,
  NDH_09: `/assets/images/products/PullHandles/NDH_09.jpeg`,
  NDH_10: `/assets/images/products/PullHandles/NDH_10.jpeg`,
  NDH_11: `/assets/images/products/PullHandles/NDH_11.jpeg`,
  NDH_12: `/assets/images/products/PullHandles/NDH_12.jpeg`,
  NDH_13: `/assets/images/products/PullHandles/NDH_13.jpeg`,
  NDH_14: `/assets/images/products/PullHandles/NDH_14.jpeg`,
  NDH_15: `/assets/images/products/PullHandles/NDH_15.jpeg`,
  NDH_16: `/assets/images/products/PullHandles/NDH_16.jpeg`,
  NDH_17: `/assets/images/products/PullHandles/NDH_17.jpeg`,
  NDH_18: `/assets/images/products/PullHandles/NDH_18.jpeg`,
  NDH_19: `/assets/images/products/PullHandles/NDH_19.jpeg`,
  NDH_20: `/assets/images/products/PullHandles/NDH_20.jpeg`,
  NDH_21: `/assets/images/products/PullHandles/NDH_21.jpeg`,
  NDH_22: `/assets/images/products/PullHandles/NDH_22.jpeg`,
  NDH_23: `/assets/images/products/PullHandles/NDH_23.jpeg`,
  NDH_24: `/assets/images/products/PullHandles/NDH_24.jpeg`,
};

export const cabinetHandles = {
  NCH_01: `/assets/images/products/CabinetHandles/NCH_01.jpg`,
  NCH_02: `/assets/images/products/CabinetHandles/NCH_02.jpg`,
  NCH_03: `/assets/images/products/CabinetHandles/NCH_03.jpg`,
  NCH_04: `/assets/images/products/CabinetHandles/NCH_04.jpg`,
  NCH_05: `/assets/images/products/CabinetHandles/NCH_05.jpg`,
  NCH_06: `/assets/images/products/CabinetHandles/NCH_06.jpg`,
  NCH_07: `/assets/images/products/CabinetHandles/NCH_07.jpg`,
  NCH_08: `/assets/images/products/CabinetHandles/NCH_08.jpg`,
  NCH_09: `/assets/images/products/CabinetHandles/NCH_09.jpg`,
  NCH_10: `/assets/images/products/CabinetHandles/NCH_10.jpg`,
  NCH_11: `/assets/images/products/CabinetHandles/NCH_11.jpg`,
  NCH_12: `/assets/images/products/CabinetHandles/NCH_12.jpg`,
  NCH_13: `/assets/images/products/CabinetHandles/NCH_13.jpg`,
  NCH_14: `/assets/images/products/CabinetHandles/NCH_14.jpg`,
  NCH_15: `/assets/images/products/CabinetHandles/NCH_15.jpg`,
  NCH_16: `/assets/images/products/CabinetHandles/NCH_16.jpg`,
};

export const drawerSlideImages = {
  NPDS_01: `/assets/images/products/DrawerSlides/NPDS_01.jpeg`,
  NPDS_02: `/assets/images/products/DrawerSlides/NPDS_02.jpeg`,
  NPDS_03: `/assets/images/products/DrawerSlides/NPDS_03.jpeg`,
};

export const profileHandlesImages = {
  NPAP_201: `/assets/images/products/ProfileHandles/NPAP_201.jpg`,
  NPAP_202: `/assets/images/products/ProfileHandles/NPAP_202.jpg`,
  NPAP_203: `/assets/images/products/ProfileHandles/NPAP_203.jpg`,
  NPAP_204: `/assets/images/products/ProfileHandles/NPAP_204.jpg`,
  NPAP_205: `/assets/images/products/ProfileHandles/NPAP_205.jpg`,
  NPAP_206: `/assets/images/products/ProfileHandles/NPAP_206.jpg`,
  NPAP_207: `/assets/images/products/ProfileHandles/NPAP_207.jpg`,
  NPAP_208: `/assets/images/products/ProfileHandles/NPAP_208.jpg`,
};

export default mainCategoryImages;
