import React from "react";
import { useRouter } from "../../hooks/useRouter";

const CategoryCard = ({ image, title, path, id }) => {
  const router = useRouter();

  const handleClick = (e) => {
    const path = e.target.name;

    router.push(`/products/${path}`);
  };

  return (
    <div className="Nevitta__ProductCard">
      <div className="productImg">
        <img src={image} alt="demo" id={id} name={path} onClick={handleClick} />
      </div>
      <div className="productTitle" id={id}>
        {title}
      </div>
    </div>
  );
};

export default CategoryCard;
