import React from "react";
import countries from "../data/countries.json";

import Faq from "react-faq-component";
const Inquiry = (props) => {
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "Can You Make Your Own Designed Products?",
        content: "Yes, we can develop custom design as per your requirements!",
      },
      {
        title: "Can you supply in OEM?",
        content: "Yes,we can!",
      },
      {
        title: "which Base Material used in Products?",
        content:
          "Nevitta  Architectural Products used four different Material in Products i.e. Zinc, Brass, Aluminum,  Stainless Steel.",
      },
      {
        title: "Which Finishes are Available in your Products?",
        content:
          "Polish Chrome/ Antique Brass / Black Matt / Black Glossy / Black Satin /  Satin Matt / Satin Chrome / Copper Antique / Polish Gold / PVD Gold / PVD  Rose Gold / PVD Black",
      },
      {
        title: "Where our products made?",
        content: "Our almost Process is inhouse only.",
      },
    ],
  };
  return (
    <div className="Nevitta__InquiryWrapper">
      <div className="Nevitta__InquiryHeading">
        <h1>Inquiry</h1>
      </div>

      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters mb-5">
                  <div className="col-md-12">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <div id="form-message-warning" className="mb-4">
                        d
                      </div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        autoComplete="off"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="email">
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="name">
                                Company Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="CompanyName"
                                id="CompanyName"
                                placeholder="Company Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="email">
                                Phone No.
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="phone"
                                id="phone"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="subject">
                                Select Country
                              </label>
                              <select
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                defaultValue="India"
                              >
                                {countries.map((e) => (
                                  <option key={e.name} value={e.name}>
                                    {e.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" for="name">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                id="city"
                                placeholder="City"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="subject">
                                Select Product Code
                              </label>
                              <select
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                defaultValue="India"
                              >
                                {countries.map((e) => (
                                  <option key={e.name} value={e.name}>
                                    {e.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" for="#">
                                Message
                              </label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder="Message"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                value="Send Message"
                                className="btn btn-primary"
                              />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq
            data={data}
            styles={{
              titleTextColor: "#d2ae6d",
              rowTitleColor: "#fff",
              bgColor: "transperent",
              rowContentColor: "#d2ae6d",
              arrowColor: "#d2ae6d",
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default Inquiry;
