import React, { useContext } from "react";

import logo from "../assets/img/logo.png";
import Fade from "react-reveal/Fade";
import mainCategory from "../data/mainCategory";
import CategoryCard from "../components/Card/CategoryCard";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

const Category = () => {
  return (
    <div className="Nevitta__Products">
      <div className="Nevitta__heading">
        <h1>Products Of</h1>
        <img src={logo} alt="Nevitta Logo" />
      </div>
      <Breadcrumbs />
      <div className="Products__Container">
        {mainCategory.map((e, index) => {
          const animationDelay = (index) => {
            return (index + 1) * 100;
          };

          return (
            <Fade
              right
              duration={1800}
              delay={animationDelay(index)}
              key={index}
            >
              <CategoryCard
                image={e.image}
                title={e.title}
                path={e.path}
                id={(index += 1)}
                key={index}
              />
            </Fade>
          );
        })}
      </div>
    </div>
  );
};

export default Category;
