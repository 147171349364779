import React, { useState } from "react";

export const GeneralContext = React.createContext({
  isOpen: false,
  isModalOpen: false,
  data: null,
  setOpen: () => {},
  setModalOpen: () => {},
  setData: () => {},
});

const StateContext = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);

  return (
    <GeneralContext.Provider
      value={{
        isOpen: isOpen,
        modalOpen: isModalOpen,
        data: data,
        setOpen: setOpen,
        setModalOpen: setModalOpen,
        setData: setData,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default StateContext;
