import React from "react";
import AliceCarousel from "react-alice-carousel";

const ProductCarousel = ({ items }) => {
  return (
    <AliceCarousel
      animationType="fadeout"
      autoWidth
      mouseTracking
      disableButtonsControls
      infinite
      paddingRight={10}
      paddingLeft={10}
      autoPlay
      autoPlayInterval={2000}
      animationDuration={1000}
      controlsStrategy="responsive"
      activeIndex={4}
      keyboardNavigation={true}
      animationDuration={800}
      items={items}
    />
  );
};

export default ProductCarousel;
