import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const DynamicUserBreadcrumb = ({ match }) => <span>{match.params.path}</span>;

// define custom breadcrumbs for certain routes.
// breadcumbs can be components or strings.
const routes = [
  { path: "/products/:path", breadcrumb: DynamicUserBreadcrumb },
  { path: "/products", breadcrumb: "Products" },
];

// map & render your breadcrumb components however you want.
const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className="Breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <NavLink to={match.url} key={index}>
          {breadcrumb}
        </NavLink>
      ))}
    </div>
  );
};

export default Breadcrumbs;
