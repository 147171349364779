import { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import "./style/main.css";

// Pages
import Home from "./pages/Home";
import Category from "./pages/Category";
import Products from "./pages/Products";
import Contact from "./pages/ContactUs";

// Components
import Layout from "./components/layout/Layout";
import Loader from "./components/loader/Loader";
import Cursor from "./components/cursor/Cursor";
import ScrollToTop from "./components/ScrollToTop";
import Inquiry from "./pages/Inquiry";
import About from "./pages/About";

const App = () => {
  const [loading, setLoading] = useState(true);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  useEffect(() => {
    (async () => {
      await sleep(3800);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Cursor />
      <Loader loading={loading} />
      <ScrollToTop />
      {loading === false && (
        <div className="App">
          <Layout>
            <Switch>
              <Route path="/about" component={About} exact />
              <Route path="/inquiry" component={Inquiry} exact />
              <Route path="/contact" component={Contact} exact />
              <Route path="/products/:path" component={Products} />
              <Route path="/products" component={Category} exact />
              <Route path="/" component={Home} exact />
              {/* <Route path="/test" component={Test} exact /> */}
            </Switch>
          </Layout>
        </div>
      )}
    </>
  );
};

export default App;
