import styled from "styled-components";

export const ProductModalContainer = styled.div`
  position: fixed;
  height: 80vh;
  width: 80%;
  top: 0;
  left: ${(props) => (props.open ? "0" : "-200%")};
  right: 0;
  bottom: 0;
  position: fixed;
  margin: 100px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  transition: all ease-in-out 0.5s;
  background-color: #808080;
  z-index: 999999;
  @media screen and (max-width: 1024px) {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 726px) {
    height: 40vh;
    width: 100%;
  }
`;
export const ProductModalDetailContainer = styled.div`
  height: 90vh;
  grid-column: span 1;
  padding: 0em 2em;
  background-color: #505050;
  color: black;
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: inherit;
  }
  @media screen and (max-width: 726px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 50vh;
  }
`;

export const ProductModalImageContainer = styled.img`
  object-fit: contain;
  height: inherit;
  width: 100%;
  grid-column: span 1;
  background-color: white;
`;

export const ProductDetail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 2em;
  color: #fff;
`;

export const ModalCloseBtn = styled.a`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  font-size: 2em;
  color: white;
  mix-blend-mode: darken;
`;
