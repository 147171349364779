import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/captioned.css";
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";
import "react-awesome-slider/dist/lettering.css";

const Carousel = () => {
  return (
    <AwesomeSlider animation="scaleOutAnimation" bullets={false}>
      <div data-src="https://images.pexels.com/photos/1643384/pexels-photo-1643384.jpeg">
        <p className="carouselMainText">
          Sometimes science is more art than science.
        </p>
        <p className="carouselSubText">
          Sometimes science is more art than science.
        </p>
      </div>
      <div data-src="https://images.pexels.com/photos/6492403/pexels-photo-6492403.jpeg">
        <p className="carouselMainText">
          Sometimes science is more art than science.
        </p>
        <p className="carouselSubText">
          Sometimes science is more art than science.
        </p>
      </div>
      <div data-src="https://images.pexels.com/photos/1571458/pexels-photo-1571458.jpeg">
        <p className="carouselMainText">
          Sometimes science is more art than science.
        </p>
        <p className="carouselSubText">
          Sometimes science is more art than science.
        </p>
      </div>
    </AwesomeSlider>
  );
};

export default Carousel;
