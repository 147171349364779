import Hamburger from "hamburger-react";
import React, { useContext } from "react";
import { FiPhoneCall } from "react-icons/fi";
import Logo from "../../assets/img/logo.png";
import { GeneralContext } from "../../context/StateContext";
import { Link } from "react-router-dom";

const Header = () => {
  const { isOpen, setOpen } = useContext(GeneralContext);
  return (
    <div className="Nevitta__HeaderContainer Nevitta__container">
      <div className="Nevitta__Header wrapper-container">
        <div className="bottom-bar">
          <ul>
            <Link to="/contact" className="headerContactUs">
              <li>Contact Us</li>
            </Link>
            <li>
              <Link to="/">
                <img src={Logo} />
              </Link>
            </li>
            <li>
              <Hamburger
                className="mobileHam"
                toggled={isOpen}
                toggle={setOpen}
                color="#D2AE6D"
                size={10}
                duration={0.8}
                rounded
                hideOutline="false"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
