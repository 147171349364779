import {
  cabinetHandles,
  mortiseHandlesImages,
  drawerKnobsImages,
  ssHandlesImages,
  towerBoltImages,
  wallHooksImages,
  DoorCloserandViewerImages,
  HingesImages,
  pullHandles,
  drawerSlideImages,
  profileHandlesImages,
} from "./images";

export const data = [
  // Cabinet Handles done
  [
    {
      title: "NCH_01",
      image: `${cabinetHandles.NCH_01}`,
      id: "1",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_02",
      image: `${cabinetHandles.NCH_02}`,
      id: "2",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_03",
      image: `${cabinetHandles.NCH_03}`,
      id: "3",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_04",
      image: `${cabinetHandles.NCH_04}`,
      id: "4",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_05",
      image: `${cabinetHandles.NCH_05}`,
      id: "5",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_06",
      image: `${cabinetHandles.NCH_06}`,
      id: "6",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_07",
      image: `${cabinetHandles.NCH_07}`,
      id: "7",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_08",
      image: `${cabinetHandles.NCH_08}`,
      id: "8",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_09",
      image: `${cabinetHandles.NCH_09}`,
      id: "9",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_10",
      image: `${cabinetHandles.NCH_10}`,
      id: "10",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_11",
      image: `${cabinetHandles.NCH_11}`,
      id: "11",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_12",
      image: `${cabinetHandles.NCH_12}`,
      id: "12",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_13",
      image: `${cabinetHandles.NCH_13}`,
      id: "13",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_14",
      image: `${cabinetHandles.NCH_14}`,
      id: "14",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_15",
      image: `${cabinetHandles.NCH_15}`,
      id: "15",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
    {
      title: "NCH_16",
      image: `${cabinetHandles.NCH_16}`,
      id: "16",
      finishes: "Chrome, Chrome Two Tone, Brass Antique, S.S. Matt",
      sizes: "96mm, 160mm, 224mm, 288mm",
    },
  ],
  // Pull Handles done
  [
    {
      title: "NPH_01",
      image: `${pullHandles.NDH_01}`,
      id: "1",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_02",
      image: `${pullHandles.NDH_02}`,
      id: "2",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_03",
      image: `${pullHandles.NDH_03}`,
      id: "3",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_04",
      image: `${pullHandles.NDH_04}`,
      id: "4",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_05",
      image: `${pullHandles.NDH_05}`,
      id: "5",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_06",
      image: `${pullHandles.NDH_06}`,
      id: "6",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_07",
      image: `${pullHandles.NDH_07}`,
      id: "7",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_08",
      image: `${pullHandles.NDH_08}`,
      id: "8",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_09",
      image: `${pullHandles.NDH_09}`,
      id: "9",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_10",
      image: `${pullHandles.NDH_10}`,
      id: "10",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_11",
      image: `${pullHandles.NDH_11}`,
      id: "11",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_12",
      image: `${pullHandles.NDH_12}`,
      id: "12",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_13",
      image: `${pullHandles.NDH_13}`,
      id: "13",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_14",
      image: `${pullHandles.NDH_14}`,
      id: "14",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_15",
      image: `${pullHandles.NDH_15}`,
      id: "15",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_16",
      image: `${pullHandles.NDH_16}`,
      id: "16",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_17",
      image: `${pullHandles.NDH_17}`,
      id: "17",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_18",
      image: `${pullHandles.NDH_18}`,
      id: "18",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_19",
      image: `${pullHandles.NDH_19}`,
      id: "19",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_20",
      image: `${pullHandles.NDH_20}`,
      id: "20",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_21",
      image: `${pullHandles.NDH_21}`,
      id: "21",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_22",
      image: `${pullHandles.NDH_22}`,
      id: "22",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_23",
      image: `${pullHandles.NDH_23}`,
      id: "23",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
    {
      title: "NPH_24",
      image: `${pullHandles.NDH_24}`,
      id: "24",
      finishes: "ANTIQUE BRASS, MATT SS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch, 10inch, 12inch, 16inch, 18inch, 24inch",
    },
  ],
  // Mortise Handles done
  [
    {
      title: "NPMH_01",
      image: `${mortiseHandlesImages.NPMH_01}`,
      id: "1",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_02",
      image: `${mortiseHandlesImages.NPMH_02}`,
      id: "2",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_03",
      image: `${mortiseHandlesImages.NPMH_03}`,
      id: "3",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_04",
      image: `${mortiseHandlesImages.NPMH_04}`,
      id: "4",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_05",
      image: `${mortiseHandlesImages.NPMH_05}`,
      id: "5",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_06",
      image: `${mortiseHandlesImages.NPMH_06}`,
      id: "6",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_07",
      image: `${mortiseHandlesImages.NPMH_07}`,
      id: "7",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_08",
      image: `${mortiseHandlesImages.NPMH_08}`,
      id: "8",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_09",
      image: `${mortiseHandlesImages.NPMH_09}`,
      id: "9",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_10",
      image: `${mortiseHandlesImages.NPMH_10}`,
      id: "10",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_11",
      image: `${mortiseHandlesImages.NPMH_11}`,
      id: "11",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_12",
      image: `${mortiseHandlesImages.NPMH_12}`,
      id: "12",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_13",
      image: `${mortiseHandlesImages.NPMH_13}`,
      id: "13",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_14",
      image: `${mortiseHandlesImages.NPMH_14}`,
      id: "14",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_15",
      image: `${mortiseHandlesImages.NPMH_15}`,
      id: "15",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_16",
      image: `${mortiseHandlesImages.NPMH_16}`,
      id: "16",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_17",
      image: `${mortiseHandlesImages.NPMH_17}`,
      id: "17",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_18",
      image: `${mortiseHandlesImages.NPMH_18}`,
      id: "18",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_19",
      image: `${mortiseHandlesImages.NPMH_19}`,
      id: "19",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_20",
      image: `${mortiseHandlesImages.NPMH_20}`,
      id: "20",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_21",
      image: `${mortiseHandlesImages.NPMH_21}`,
      id: "21",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_22",
      image: `${mortiseHandlesImages.NPMH_22}`,
      id: "22",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_23",
      image: `${mortiseHandlesImages.NPMH_23}`,
      id: "23",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_24",
      image: `${mortiseHandlesImages.NPMH_24}`,
      id: "24",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_25",
      image: `${mortiseHandlesImages.NPMH_25}`,
      id: "25",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_26",
      image: `${mortiseHandlesImages.NPMH_26}`,
      id: "26",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_27",
      image: `${mortiseHandlesImages.NPMH_27}`,
      id: "27",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_28",
      image: `${mortiseHandlesImages.NPMH_28}`,
      id: "28",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_29",
      image: `${mortiseHandlesImages.NPMH_29}`,
      id: "29",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_30",
      image: `${mortiseHandlesImages.NPMH_30}`,
      id: "30",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_31",
      image: `${mortiseHandlesImages.NPMH_31}`,
      id: "31",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_32",
      image: `${mortiseHandlesImages.NPMH_32}`,
      id: "32",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_33",
      image: `${mortiseHandlesImages.NPMH_33}`,
      id: "33",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_34",
      image: `${mortiseHandlesImages.NPMH_34}`,
      id: "34",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_35",
      image: `${mortiseHandlesImages.NPMH_35}`,
      id: "35",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_36",
      image: `${mortiseHandlesImages.NPMH_36}`,
      id: "36",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_37",
      image: `${mortiseHandlesImages.NPMH_37}`,
      id: "37",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
    {
      title: "NPMH_38",
      image: `${mortiseHandlesImages.NPMH_38}`,
      id: "38",
      finishes: "ANTIQUE BRASS, GRAPHITE BLACK, COPPER ANTIQUE",
      sizes: "8inch Plate, 10inch Plate",
    },
  ],
  // Profile Handles done
  [
    {
      title: "NPAP_201",
      image: `${profileHandlesImages.NPAP_201}`,
      id: "1",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_202",
      image: `${profileHandlesImages.NPAP_202}`,
      id: "2",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_203",
      image: `${profileHandlesImages.NPAP_203}`,
      id: "3",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_204",
      image: `${profileHandlesImages.NPAP_204}`,
      id: "4",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_205",
      image: `${profileHandlesImages.NPAP_205}`,
      id: "5",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_206",
      image: `${profileHandlesImages.NPAP_206}`,
      id: "6",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_207",
      image: `${profileHandlesImages.NPAP_207}`,
      id: "7",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
    {
      title: "NPAP_208",
      image: `${profileHandlesImages.NPAP_208}`,
      id: "8",
      finishes:
        "Antique Brass, Matt SS, Chrome Polished, Matt Black, Rose Gold, Satin Chrome, Copper Antique",
      sizes:
        "150mm, 200mm, 250mm, 300mm, 350mm, 400mm, 450mm, 500mm, 550mm, 600mm",
    },
  ],
  // DrawerKnobs done
  [
    {
      title: "NPDN_01",
      image: `${drawerKnobsImages.NPDN_01}`,
      id: "1",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_02",
      image: `${drawerKnobsImages.NPDN_02}`,
      id: "2",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_03",
      image: `${drawerKnobsImages.NPDN_03}`,
      id: "3",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_04",
      image: `${drawerKnobsImages.NPDN_04}`,
      id: "4",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_05",
      image: `${drawerKnobsImages.NPDN_05}`,
      id: "5",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_06",
      image: `${drawerKnobsImages.NPDN_06}`,
      id: "6",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_07",
      image: `${drawerKnobsImages.NPDN_07}`,
      id: "7",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_08",
      image: `${drawerKnobsImages.NPDN_08}`,
      id: "8",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_09",
      image: `${drawerKnobsImages.NPDN_09}`,
      id: "9",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_10",
      image: `${drawerKnobsImages.NPDN_10}`,
      id: "10",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_11",
      image: `${drawerKnobsImages.NPDN_11}`,
      id: "11",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
    {
      title: "NPDN_12",
      image: `${drawerKnobsImages.NPDN_12}`,
      id: "12",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Black Matt & Ceramic, S.S Matt & Ceramic",
      sizes: "30mm, 32mm, 25mm, 20mm, 36mm",
    },
  ],
  // SS Handles remaining
  [
    {
      title: "NPSSDH_01",
      image: `${ssHandlesImages.NPSSDH_01}`,
      id: "1",
      finishes: "SS,PS,PV,Powder Coated",
      centertocenterofhole: "200mm,350mm,450mm,600mm,1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_02",
      image: `${ssHandlesImages.NPSSDH_02}`,
      id: "2",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200m, 350m, 450m, 600m, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_03",
      image: `${ssHandlesImages.NPSSDH_03}`,
      id: "3",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200m, 350m, 450m, 600m, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_04",
      image: `${ssHandlesImages.NPSSDH_04}`,
      id: "4",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200m, 350m, 450m, 600m, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_05",
      image: `${ssHandlesImages.NPSSDH_05}`,
      id: "5",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200m, 350m, 450m, 600m, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_06",
      image: `${ssHandlesImages.NPSSDH_06}`,
      id: "6",
      finishes: "SS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_07",
      image: `${ssHandlesImages.NPSSDH_07}`,
      id: "7",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_08",
      image: `${ssHandlesImages.NPSSDH_08}`,
      id: "8",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_09",
      image: `${ssHandlesImages.NPSSDH_09}`,
      id: "9",
      finishes: "SSS,PSS,PVS,Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },

    {
      title: "NPSSDH_10",
      image: `${ssHandlesImages.NPSSDH_10}`,
      id: "10",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_11",
      image: `${ssHandlesImages.NPSSDH_11}`,
      id: "11",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_12",
      image: `${ssHandlesImages.NPSSDH_12}`,
      id: "12",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_13",
      image: `${ssHandlesImages.NPSSDH_13}`,
      id: "13",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_14",
      image: `${ssHandlesImages.NPSSDH_14}`,
      id: "14",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    {
      title: "NPSSDH_15",
      image: `${ssHandlesImages.NPSSDH_15}`,
      id: "15",
      finishes: "SSS, PSS, PVD, Powder Coated",
      centertocenterofhole: "200mm, 350mm, 450mm, 600mm, 1200mm",
      handlediameter: "22mm, 25mm, 32mm, 38mm",
    },
    //
    {
      title: "NPSSMH_01",
      image: `${ssHandlesImages.NPSSMH_01}`,
      id: "16",
      finishes: "SSS, PSS",
      sizes: "22mm",
    },
    {
      title: "NPSSMH_02",
      image: `${ssHandlesImages.NPSSMH_02}`,
      id: "17",
      finishes: "SSS, PSS",
      sizes: "22mm",
    },
    {
      title: "NPSSMH_03",
      image: `${ssHandlesImages.NPSSMH_03}`,
      id: "18",
      finishes: "SSS, PSS",
      sizes: "22mm",
    },
    {
      title: "NPSSMH_04",
      image: `${ssHandlesImages.NPSSMH_04}`,
      id: "19",
      finishes: "SSS, PSS",
      sizes: "22mm",
    },
    //NPSSNR_01 remaining
    {
      title: "NPSSNR_01",
      image: `${ssHandlesImages.NPSSNR_01}`,
      id: "20",
      finishes: "Stainless Steel",
    },
    {
      title: "NPSSNR_02",
      image: `${ssHandlesImages.NPSSNR_02}`,
      id: "21",
      finishes: "Stainless Steel",
    },
    {
      title: "NPSSNR_03",
      image: `${ssHandlesImages.NPSSNR_03}`,
      id: "22",
      finishes: "Stainless Steel",
    },
    {
      title: "NPSSNR_04",
      image: `${ssHandlesImages.NPSSNR_04}`,
      id: "23",
      finishes: "Stainless Steel",
    },
    {
      title: "NPSSNR_05",
      image: `${ssHandlesImages.NPSSNR_05}`,
      id: "24",
      finishes: "Stainless Steel",
    },
    // NPSSTH_01 remaining
    {
      title: "NPSSTH_01",
      image: `${ssHandlesImages.NPSSTH_01}`,
      id: "25",
    },
    {
      title: "NPSSTH_02",
      image: `${ssHandlesImages.NPSSTH_02}`,
      id: "26",
    },
    {
      title: "NPSSTH_03",
      image: `${ssHandlesImages.NPSSTH_03}`,
      id: "27",
    },
    {
      title: "NPSSTH_04",
      image: `${ssHandlesImages.NPSSTH_04}`,
      id: "28",
    },
  ],
  // Drawer Slides done
  [
    {
      title: "NPDS_01",
      image: `${drawerSlideImages.NPDS_01}`,
      id: "1",
      finishes: "Zinc, black",
      sizes: "10inch-24inch",
      width: "45mm",
      weight: "72gm",
      loadCapacity: "45kg",
      thickness: "1.2*1.2*1.2mm",
    },
    {
      title: "NPDS_02",
      image: `${drawerSlideImages.NPDS_02}`,
      id: "2",
      finishes: "Zinc, black",
      width: "35mm",
      weight: "45gm",
      loadCapacity: "35kg",
      sizes: "10inch-22inch",
      thickness: "1*1*1mm",
    },
    {
      title: "NPDS_03",
      image: `${drawerSlideImages.NPDS_03}`,
      id: "3",
      finishes: "Zinc, black",
      width: "45mm",
      weight: "68gm",
      loadCapacity: "45kg",
      sizes: "10inch-24inch",
      thickness: "1.2*1.2*1.2mm",
    },
  ],
  // LockBody&Cylinder made shivam remaining
  [],
  // [
  //   {
  //     title: "NPLBC_01",
  //     image: `${LockBodyandCylinderImages.NPLBC_01}`,
  //     id: "1",
  //     finishes: "Stainles Steel and Brass",
  //     sizes: "45*85mm, 60*85mm",
  //   },

  //   {
  //     title: "NPLBC_02",
  //     image: `${LockBodyandCylinderImages.NPLBC_02}`,
  //     id: "2",
  //   },

  //   {
  //     title: "NPLBC_03",
  //     image: `${LockBodyandCylinderImages.NPLBC_03}`,
  //     id: "3",
  //   },
  // ],
  // Hinges done
  [
    {
      title: "NPH_01",
      image: `${HingesImages.NPH_01}`,
      id: "1",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",

      sizes:
        "3*1/2inch, 3*3/8inch, 3*5/8inch, 3*3/4inch, 4*3/4inch, 3*7/8inch, 4*7/8inch, 3*1inch, 4*1inch",
    },
    {
      title: "NPH_02",
      image: `${HingesImages.NPH_02}`,
      id: "2",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",

      sizes:
        "4*7/8inch, 4*1inch, 4*1-1/8inch, 4*1-1/4inch, 4*1-1/2inch, 5*1-1/8inch, 5*1-1/4inch, 5*1-1/2inch, 6*1-1/8inch, 6*1-1/4inch, 6*1-1/2inch",
    },
    {
      title: "NPH_03",
      image: `${HingesImages.NPH_03}`,
      id: "3",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*3/8*5/8*3/32inch, 3*1/2*3/4*3/32inch, 4*1/2*3/4*3/32inch, 3*1/2*1*3/32inch, 4*1/2*1*3/32inch",
    },
    {
      title: "NPH_04",
      image: `${HingesImages.NPH_04}`,
      id: "4",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",

      sizes: "3*1-1/8inch, 4*1-1/8inch, 5*1-1/8inch, 6*1-1/8inch, 6*1-1/4inch",
    },
    {
      title: "NPH_05",
      image: `${HingesImages.NPH_05}`,
      id: "5",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",

      sizes: "4*1-1/4inch, 5*1-1/4inch, 6*1-1/4inch, 8*1-1/4inch, 10*1-1/4inch",
    },
    {
      title: "NPH_06",
      image: `${HingesImages.NPH_06}`,
      id: "6",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",

      sizes:
        "4*3inch, 5*3inch, 6*3inch, 4*3-1/2inch, 5*3-1/2inch, 6*3-1/2inch, 8*3-1/2inch",
    },
  ],
  // TowerBolt done
  [
    {
      title: "NPTB_701",
      image: `${towerBoltImages.NPTB_701}`,
      id: "1",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_702",
      image: `${towerBoltImages.NPTB_702}`,
      id: "2",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_703",
      image: `${towerBoltImages.NPTB_703}`,
      id: "3",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_704",
      image: `${towerBoltImages.NPTB_704}`,
      id: "4",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_705",
      image: `${towerBoltImages.NPTB_705}`,
      id: "5",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_706",
      image: `${towerBoltImages.NPTB_706}`,
      id: "6",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
    {
      title: "NPTB_707",
      image: `${towerBoltImages.NPTB_707}`,
      id: "7",
      finishes:
        "Stainless Steel, Buff Polished, Brass Antique, Satin Lacquer, Satin Gold, Clear Lacquer, Chrome Polished, Copper Antique",
      sizes:
        "3*0.290inch, 4*0.290inch, 6*0.290inch, 8*0.290inch, 10*0.290inch, 12*0.290inch",
    },
  ],
  // Door Closer & Viewer done
  [
    {
      title: "NPDC_01",
      image: `${DoorCloserandViewerImages.NPDC_01}`,
      id: "1",
      finishes: "Silver, Golden, Black and Brown",
      sizes: "185 x 36.8 x 40mm",
    },
    {
      title: "NPDC_02",
      image: `${DoorCloserandViewerImages.NPDC_02}`,
      id: "2",
      finishes: "Silver, Golden, Black and Brown",
      sizes: "185 x 36.8 x 40mm",
    },
    {
      title: "NPDV_03",
      image: `${DoorCloserandViewerImages.NPDV_03}`,
      id: "3",
      finishes: "S.S. Finish, Satin Lacquer, Chrome Polished, Brass Antique",
      sizes: "2inch, 3inch",
    },
  ],
  // Wall Hooks done
  [
    {
      title: "NPWH_01",
      image: `${wallHooksImages.NPWH_01}`,
      id: "1",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Silver Satin & Ceramic, Rose Gold & Ceramic, S.S Matt & Ceramic, Chrome & Ceramic, Gold & Ceramic",
      sizes: "59.5L*25.8W*59.5H",
    },
    {
      title: "NPWH_02",
      image: `${wallHooksImages.NPWH_02}`,
      id: "2",
      finishes:
        "Brass Antique & Ceramic, Black Nickel & Ceramic, Silver Satin & Ceramic, Rose Gold & Ceramic, S.S Matt & Ceramic, Chrome & Ceramic, Gold & Ceramic",
      sizes: "50L*30W*30H",
    },
  ],
];
