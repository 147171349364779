import React, { useContext } from "react";
import "./ProductModalStyles";
import {
  ModalCloseBtn,
  ProductDetail,
  ProductModalContainer,
  ProductModalDetailContainer,
  ProductModalImageContainer,
} from "./ProductModalStyles";

import { RiCloseFill } from "react-icons/ri";
import { GeneralContext } from "../../context/StateContext";

const ProductModal = ({ isOpen }) => {
  const { setModalOpen, data } = useContext(GeneralContext);

  const handleModalClose = () => setModalOpen(false);
  return (
    <ProductModalContainer open={isOpen}>
      <ProductModalImageContainer alt="Demo" src={data?.image} />
      <ModalCloseBtn onClick={handleModalClose}>
        <RiCloseFill />
      </ModalCloseBtn>
      <ProductModalDetailContainer>
        <ProductDetail>
          <span style={{ fontWeight: "bold" }}>SKU Code:</span>
          <span style={{ fontSize: "1rem" }}>{data && data.title}</span>
        </ProductDetail>
        {data && data.sizes && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Sizes :</span>
            <span style={{ fontSize: "1rem" }}>{data.sizes}</span>
          </ProductDetail>
        )}
        {data && data.finishes && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Finishes :</span>
            <span style={{ fontSize: "1rem" }}>{data.finishes}</span>
          </ProductDetail>
        )}
        {data && data.centertocenterofhole && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>
              Center To Center Of Hole :
            </span>
            <span style={{ fontSize: "1rem" }}>
              {data.centertocenterofhole}
            </span>
          </ProductDetail>
        )}
        {data && data.handlediameter && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Handle Diameter :</span>
            <span style={{ fontSize: "1rem" }}>{data.handlediameter}</span>
          </ProductDetail>
        )}
        {data && data.width && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Width :</span>
            <span style={{ fontSize: "1rem" }}>{data.width}</span>
          </ProductDetail>
        )}
        {data && data.weight && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Weight :</span>
            <span style={{ fontSize: "1rem" }}>{data.weight}</span>
          </ProductDetail>
        )}
        {data && data.loadCapacity && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Load Capacity :</span>
            <span style={{ fontSize: "1rem" }}>{data.loadCapacity}</span>
          </ProductDetail>
        )}
        {data && data.thickness && (
          <ProductDetail>
            <span style={{ fontWeight: "bold" }}>Thickness :</span>
            <span style={{ fontSize: "1rem" }}>{data.thickness}</span>
          </ProductDetail>
        )}
      </ProductModalDetailContainer>
    </ProductModalContainer>
  );
};

export default ProductModal;
