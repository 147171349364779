import React, { useContext } from "react";
import ProductCard from "../components/Card/ProductCard";
import Fade from "react-reveal/Fade";
import { data } from "../data/data";
import ProductModal from "../components/ProductModal/ProductModal";
import { GeneralContext } from "../context/StateContext";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { useRouter } from "../hooks/useRouter";
const Products = () => {
  const { query, push } = useRouter();
  const { modalOpen, image } = useContext(GeneralContext);

  if (!query.categoryId) {
    push("/products");
    return false;
  }

  return (
    <div className="Nevitta__Products">
      <Breadcrumbs />
      <div className="Products__Container">
        {data[query.categoryId - 1].map((e, index) => {
          const animationDelay = (index) => {
            return index * 100 - 200;
          };
          return (
            <Fade
              right
              duration={1800}
              delay={animationDelay(index)}
              key={index}
            >
              <ProductCard data={e} key={index} />
            </Fade>
          );
        })}
      </div>
      {modalOpen && <ProductModal isOpen={modalOpen} image={image} />}
    </div>
  );
};

export default Products;
