import React from "react";

import logo from "../assets/img/logo.png";
const About = () => {
  return (
    <div className="Nevitta__AboutWrapper">
      <div className="Nevitta__AboutContainer">
        <div className="Nevitta__CompanyProfile">
          <div className="Nevitta__CompanyProfileContent">
            <div className="Nevitta__heading">
              <h1>About</h1>
              <img src={logo} alt="Nevitta Logo" />
            </div>
            <p>
              We started manufacturing in 2006 as a Name with Duster Metal
              Products Pvt. Ltd. Now we are going globally with Nevitta. We
              understood the importance of innovative design and an
              uncompromising commitment to craft and quality. Our range includes
              designer zinc, Brass, Stainless Steel of cabinet handles, mortise
              handle, Pull handles, Drawer knobs, drawer Slide and fitting
              accessories. In this website we include existing improved quality
              products and innovative designer products also. Our hardware
              fittings accessories are highly innovative, highly designed
              product of expression that open up infinite possibilities for
              defining the modern living space.
            </p>
          </div>
          <div className="Nevitta__CompanyProfileImage">
            <img
              src={"/assets/Images/backgrounds/cpimg.jpg"}
              alt="ComapnyImage"
            />
          </div>
        </div>

        <div className="Nevitta__CompanyVisionContainer">
          <div className="Nevitta__CompanyVision">
            <div className="Nevitta__heading">
              <h1>Vision of </h1>
              <img src={logo} alt="Nevitta Logo" />
            </div>
            <p>
              We prides on quality and performance. The sole vision of the
              company is to deliver products and services conforming to
              International standards and satisfying the requirements and the
              expectations of our customers in respect of quality, cost,
              performance, safety and reliability.
            </p>
          </div>
        </div>
        <div className="Nevitta__CompanyQuality">
          <div className="Nevitta__CompanyQualityImage">
            <img src={"/assets/Images/process/4.jpg"} alt="ComapnyImage" />
          </div>
          <div className="Nevitta__CompanyQualityContent">
            <div className="Nevitta__heading">
              <h1>Quality of</h1>
              <img src={logo} alt="Nevitta Logo" />
            </div>
            <p>
              We are even more demanding than our customers. Responsibility,
              quality and rigour are present in all stages of production and
              service. That why we apply processes of quality control which help
              us to improve and be better than we were yesterday.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="/assets/Images/iso/IAF.png" alt="" />
              <img src="/assets/Images/iso/ISO.svg" alt="" />
            </div>
            <p>
              Above all, our priority is quality. Quality in the production
              process, in each detail of our products, in the service we offer,
              in our management and in everything we do. We want to keep our
              clients satisfied. The ISO 9001:2015 certificate demonstrates
              this. We are proud to have achieved it and it offers guarantees
              for our customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
